import { get, post } from "../adapters/apiAdapter";
import { Project, ProjectCollection } from "../shared/types/Project";
import { ProjectStages, Stages } from "../shared/types/Stages";
import { ProjectTypes, ProjectType } from "../shared/types/ProjectType";

//#region Add Project
export type PostAddProjectRequestBody = {
    fluidraProjectId: string,
    active: boolean,
    name: string,
    description: string | null,
    type: number,
    stages: ProjectStages,
    departments: number[],
    capitalisationGroup: number,
    inTaxClaim: boolean
}

export async function postAddProject(requestBody: PostAddProjectRequestBody) {
    return await post(`/api/projects/add`, requestBody);
}
//#endregion

//#region Edit Project
export type PostEditProjectRequestBody = PostAddProjectRequestBody & {
    id: number
}

export async function postEditProject(requestBody: PostEditProjectRequestBody) {
    return await post(`/api/projects/edit`, requestBody);
}
//#endregion

// #region Delete Project
export type PostDeleteProjectRequestBody = {
    id: number
}

export async function postDeleteProject(requestBody: PostDeleteProjectRequestBody) {
    return await post(`/api/projects/delete`, requestBody);
}
//#endregion

//#region Get All Projects
export async function getAllProjects(): Promise<ProjectCollection> {
    const response = await get(`/api/projects/all`);
    const processedResponse: ProjectCollection = {}
    for (const id in response.data) {
        const project: Project = response.data[Number(id)]
        project.active = response.data[Number(id)].active === 1;
        project.inTaxClaim = response.data[Number(id)].inTaxClaim === 1;
        project.stages = response.data[Number(id)].stages.map((stage: any) => ({...stage, used: stage.used === 1}))
        processedResponse[Number(id)] = project
    }
    return processedResponse;
}
//#endregion

//#region Stages
export async function getStages(): Promise<Stages> {
    const response = await get(`/api/projects/stages`);
    return response.data;
}
//#endregion

//#region Types
export async function getTypes(): Promise<ProjectTypes> {
    const response = await get(`/api/projects/types`);
    const processedResponse: ProjectTypes = {}
    for (const id in response.data) {
        const projectType: ProjectType = response.data[Number(id)];
        projectType.capitalisable = response.data[Number(id)].capitalisable === 1;
        processedResponse[Number(id)] = projectType
    }
    return processedResponse;
}
//#endregion







