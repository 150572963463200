import { SQLDate } from "../types/SQLDate";
import { daysList } from "../enums/days";
import { Months, monthsList } from "../enums/months";

export function formatDate(date: Date, dashes=false): string {
    const dt = new Date(date)
    const dd = dt.getDate();
    const mm = dt.getMonth() + 1;
    const yyyy = dt.getFullYear();
  
    const separator = dashes ? '-' : '/'

    return dd + separator + mm + separator + yyyy;
}

export function formatDateForSQL(date: Date): SQLDate {
    const dt = new Date(date)
    const dd = String(dt.getDate()).padStart(2, "0");
    const mm = String(dt.getMonth() + 1).padStart(2, "0");
    const yyyy = dt.getFullYear();
  
    return `${yyyy}-${mm}-${dd}`;
}

export function SQLFormatToDate(dateString: SQLDate | string): Date {
    const dateComponents = dateString.split("-");
    const day = parseInt(dateComponents[2], 10)
    const month = parseInt(dateComponents[1], 10);
    const year = parseInt(dateComponents[0], 10);
    return new Date(year, month - 1, day);
}

export function longFormatDate(date: Date): string {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    // Function to add the appropriate suffix to the day
    function addOrdinalSuffix(day: any) {
        if (day >= 11 && day <= 13) {
            return day + 'th';
        }
        switch (day % 10) {
        case 1:
            return day + 'st';
        case 2:
            return day + 'nd';
        case 3:
            return day + 'rd';
        default:
            return day + 'th';
    }
}

  const formattedDate = `${addOrdinalSuffix(day)} of ${month}, ${year}`;
  return formattedDate;
}

export function getCurrentDate(): Date {
    return new Date();
}

export function getPreviousDate(date: Date): Date {
    const currentDate = new Date(date);
    const previousDate = new Date(currentDate.setDate(currentDate.getDate() - 1));
    return previousDate
}

export function getNextDate(date: Date): Date {
    const currentDate = new Date(date);
    const nextDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    return nextDate
}

export function generateRecentDates(numDays: number): Date[] {
    const dates = [];
    let currentDate = new Date();
  
    for (let i = 0; i < numDays; i++) {
        dates.push(currentDate);
        currentDate = getPreviousDate(currentDate);
    }
    return dates;
}

export function generateDatesList(date: Date): Date[] {
    const dates = []
    
    const today = new Date();
    const todayMinus7Days = new Date();
    todayMinus7Days.setDate(today.getDate() - 6);

    var currentDate: Date;
    if (date > todayMinus7Days && (date < today || sameDay(date, today))) {
        currentDate = today;
        for (let i = 0; i < 7; i++) {
            dates.push(currentDate);
            currentDate = getPreviousDate(currentDate);   
        }
    } else if (date < todayMinus7Days) {
        currentDate = date;

        // Go forward until it is saturday
        while (currentDate.getDay() !== 6) {
            currentDate = getNextDate(currentDate);
        }

        // Get the past 7 days from that saturday
        for (let i = 0; i < 7; i++) {
            dates.push(currentDate);
            currentDate = getPreviousDate(currentDate);   
        }
    } else {
        // A date in the future is clicked
        currentDate = date;

        // Go forward until it is saturday
        while (currentDate.getDay() !== 6) {
            currentDate = getNextDate(currentDate);
        }
        
        // Get the past 7 days from that saturday
        for (let i = 0; i < 7; i++) {
            dates.push(currentDate);
            currentDate = getPreviousDate(currentDate);   
        }
    }

    return dates;
}

export function isWeekend(date: Date): boolean {
    // Takes a date and returns true if it is a weekend, false otherwise
    const day = date.getDay();
    return day === 0 || day === 6;
}

export function dayOfWeek(date: Date): string {
    const day = date.getDay();
    return daysList[day]
}

export function getMonthName(monthNumber: number): string { 
    // Ensure the monthNumber is within the valid range (1-12)
    if (monthNumber >= 1 && monthNumber <= 12) {
        return monthsList[monthNumber - 1];
    } else {
        return "Invalid Month";
    }
}

export function getMonthNumber(monthName: Months): number {
    const monthIndex = monthsList.indexOf(monthName);
    
    // Add 1 to the index since JavaScript months are zero-based
    return monthIndex !== -1 ? monthIndex + 1 : -1; // Return -1 if the month name is not found
}

export function addMonth(date: Date): Date {
    // Clone the input date to avoid modifying the original date
    const newDate = new Date(date);

    // Get the year and month of the next month
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;

    // Handle cases where the next month is in the next year
    if (month > 11) {
        newDate.setFullYear(year + 1);
        newDate.setMonth(0); // January
    } else {
        newDate.setMonth(month);
    }

    return newDate;
}

export function subtractMonth(date: Date): Date {
    // Clone the input date to avoid modifying the original date
    const newDate = new Date(date);

    // Get the year and month of the next month
    const year = newDate.getFullYear();
    const month = newDate.getMonth() - 1;

    // Handle cases where the next month is in the next year
    if (month < 0) {
        newDate.setFullYear(year - 1);
        newDate.setMonth(11); // December
    } else {
        newDate.setMonth(month);
    }

    return newDate;
}

export function firstOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getStartDate(date: Date): SQLDate {
    // Clone the input date to avoid modifying the original date
    const newDate = new Date(subtractMonth(date));

    const mm = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const yyyy = newDate.getFullYear();

    // return new Date(yyyy, mm, 23);
    return `${yyyy}-${mm}-23`
}

export function getEndDate(date: Date): SQLDate {
    // Clone the input date to avoid modifying the original date
    const newDate = new Date(addMonth(date));

    const mm = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const yyyy = newDate.getFullYear();

    // return new Date(yyyy, mm, 6);
    return `${yyyy}-${mm}-06`
}

export function getPreviousSunday(date: Date): Date {
    var prevSunday = new Date(date);
    while (prevSunday.getDay() !== 0) {
        prevSunday = getPreviousDate(prevSunday)
    }
    return prevSunday;
}

export function getNextSaturday(date: Date): Date {
    var nextSaturday = new Date(date);
    while (nextSaturday.getDay() !== 6) {
        nextSaturday = getNextDate(nextSaturday)
    }
    return nextSaturday;
}

export function subtractWeek(inputDate: Date): Date {
    const date = new Date(inputDate);
    date.setDate(date.getDate() - 7);
    return date;
}

export function addWeek(inputDate: Date): Date {
    const date = new Date(inputDate);
    date.setDate(date.getDate() + 7);
    return date;
}

export const sameDay = (date1: Date, date2: Date) => 
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();

export const getStartAndEndDate = (month: number, year: number) => {
    const startDate = new Date(year, month - 1, 1);
    const tempDate = addMonth(startDate)
    const endDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), 0)

    return [startDate, endDate]
}

export const getMondayOfWeek = (year: number, week: number): Date => {
    // Create a date object set to January 4th of the given year
    let date = new Date(year, 0, 4); // January 4th is always in week 1
    let dayOfWeek = date.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)

    // Calculate the Monday of ISO week 1
    let firstMonday = new Date(date);
    firstMonday.setDate(date.getDate() - ((dayOfWeek + 6) % 7)); // Move back to Monday

    // Calculate the Monday of the given week
    let monday = new Date(firstMonday);
    monday.setDate(firstMonday.getDate() + (week - 1) * 7);

    return monday;
}

export const getSundayOfWeek = (year: number, week: number): Date => {
    // Create a date object set to January 4th of the given year
    let date = new Date(year, 0, 4); // January 4th is always in ISO week 1
    let dayOfWeek = date.getDay(); // Get the day of the week (0 = Sunday, ..., 6 = Saturday)

    // Calculate the Monday of ISO week 1
    let firstMonday = new Date(date);
    firstMonday.setDate(date.getDate() - ((dayOfWeek + 6) % 7)); // Move back to Monday

    // Calculate the Sunday of the given week (from first Monday)
    let sunday = new Date(firstMonday);
    sunday.setDate(firstMonday.getDate() + (week - 1) * 7 + 6); // Move forward 6 days to get Sunday of the week

    return sunday;
}
