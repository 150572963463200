import ExcelJS from 'exceljs';
import { getAllEmployees } from '../api/employees';
import { Row } from '../shared/types/excel';
import { sortByFluidraProjectId } from '../shared/functions/projects';
import { getAllProjects } from '../api/projects';
import { downloadExcelFile, rowAndColNumToCell } from '../shared/functions/excel';
import { GetLogsRequestBody, getLogs } from '../api/logs';
import { SQLFormatToDate, formatDateForSQL, getMondayOfWeek, getSundayOfWeek } from '../shared/functions/dateAndTime';
import { Logs } from '../shared/types/Logs';
import { GLOBALS } from '../values/globals';
import { getSummaryByEmployee, getSummaryByProject } from '../api/summary';
import { getDepartments } from '../api/departments';
import { EmployeeCollection } from '../shared/types/Employee';

// const GenerateTaxReport = async (year: number, departments: Record<number, boolean>) => {
const GenerateTaxReport = async (year: number) => {
    console.log(`Generating Tax Report for ${year}...`);

    const wb = new ExcelJS.Workbook();

    // Make API calls
    const allEmployees = await getAllEmployees();
    const allProjects = await getAllProjects();
    const departments = await getDepartments();
    const summary = await getSummaryByEmployee({
        startDate: `${year}-01-01`,
        endDate: `${year}-12-31`
    });
    const summaryByProject = await getSummaryByProject({
        startDate: `${year}-01-01`,
        endDate: `${year}-12-31`
    });
    
    // Filter out projects
    const filteredProjects = sortByFluidraProjectId(Object.values(allProjects)
        .filter(project => {
            // Don't include projects which are set to not included in tax claim
            if (!project.inTaxClaim) return false;
            
            // Check if there are logs for this project in the last year
            if (!project.active && !summaryByProject.hasOwnProperty(project.id)) return false;

            return true;
        })
    );
    const usedProjectIds = filteredProjects.map(project => project.id).filter(id => ![-2, -1, 0].includes(id));

    // Filter out employees that have no logs to claimable projects
    const filteredEmployees: EmployeeCollection = Object.values(allEmployees)
        .filter((employee) => {
            // Remove employees that aren't in the summary
            if (!summary.hasOwnProperty(employee.id)) return false;

            // Get the summary for the employee
            var employeeSummary = summary[employee.id];

            // Remove projects from the summary that aren't used in the tax claim
            var employeeSummaryProjectIds = employeeSummary.map(project => project.id);
            var includeEmployee = usedProjectIds.some(id => employeeSummaryProjectIds.includes(id));

            return includeEmployee;
        })
        .reduce((acc, employee) => {
            acc[employee.id] = employee;
            return acc;
        }, {} as EmployeeCollection);

    ////////////////////
    // Create summary //
    ////////////////////
    const ws = wb.addWorksheet(`Summary`);

    const columnMap: Record<string, number> = {}

    const row1: Row = [`Summary`];
    const row2: Row = [];
    const row3: Row = [null, null];
    const row4: Row = [`Name`, `Total Hours Logged`];

    var currentColumn = 3
    for (var project of filteredProjects) {
        if (project.fluidraProjectId != null) {
            // Update column map
            columnMap[project.id] = currentColumn;
            currentColumn += 1;
            
            // Add column headers
            row3.push(project.fluidraProjectId);
            row4.push(project.name);
        }
    }
    currentColumn -= 1;

    ws.addRow(row1);
    ws.addRow(row2);
    ws.addRow(row3);
    ws.addRow(row4);

    var rowCount = 4
    Object.entries(summary).forEach(([employeeId, data]) => {
        if (filteredEmployees.hasOwnProperty(Number(employeeId))) {
            var employee = filteredEmployees[Number(employeeId)];
            var employeeName = `${employee.firstName} ${employee.lastName}`;
            
            // Create row and add name
            var row: Row = new Array(currentColumn);
            row[0] = employeeName;
            
            // Add total hours logged forumula
            var formulaStartCell = rowAndColNumToCell(rowCount + 1, 3);
            var formulaEndCell = rowAndColNumToCell(rowCount + 1, currentColumn);
            row[1] = { formula: `SUM(${formulaStartCell}:${formulaEndCell})` };
            
            // Add project hours
            for (var project of data) {
                var columnNum = columnMap[project.id] - 1;
                row[columnNum] = project.hours;
            }
            
            ws.addRow(row);
            rowCount++;
        }
    });

    // Add totals
    ws.addRow([]);
    ws.addRow([]);
    var totalRow = []
    totalRow.push("Total")
    for (var col = 2; col <= currentColumn; col++) {
        var formulaStartCell = rowAndColNumToCell(4, col);
        var formulaEndCell = rowAndColNumToCell(rowCount, col);
        totalRow.push({ formula: `SUM(${formulaStartCell}:${formulaEndCell})` });
    }
    ws.addRow(totalRow);


    // Add Styles

    // Title
    ws.getCell('A1').font = { size: 18, bold: true, color: {argb: '002F75B5'} } 

    // Header font and alignment
    ws.getRow(3).alignment = { horizontal: "center" };
    ws.getRow(4).alignment = { wrapText: true, vertical: "middle", horizontal: "center" };
    ws.getRow(4).font = { bold: true };
    
    // Total row font
    ws.getRow(rowCount + 3).font = { bold: true };

    // Set column widths
    ws.getColumn(1).width = 28;
    ws.getColumn(2).width = 10;
    // ws.getColumn(3).width = 8;
    // ws.getColumn(4).width = 8;
    // ws.getColumn(5).width = 8;
    for (let i = 3; i <= currentColumn; i++) {
        ws.getColumn(i).width = 12;
    }

    // Set row height
    ws.getRow(4).height = 68;
    
    // Freeze panes
    ws.views = [
        {state: 'frozen', xSplit: 2, ySplit: 4, topLeftCell: 'C5'}
    ];


    /////////////////////////////////////
    // Create sheets for each employee //
    /////////////////////////////////////
    for (const employee of Object.values(filteredEmployees)) {
        const sheetName = `Labour - ${employee.firstName[0]} ${employee.lastName}`;
        const ws = wb.addWorksheet(sheetName)
        
        const requestBody: GetLogsRequestBody = {
            id: employee.id,
            startDate: `${year}-01-01`,
            endDate: `${year}-12-31`
        }

        const logs = await getLogs(requestBody);

        // Create a map if column numbers
        const columnMap: Record<string, number> = {
            "Week ending": 0,
            "Leave": 1,
            "Public Holiday": 2,
            "Project Hours Available": 3,
            "Total Hours Logged": 4,
        }
        var currentColumn = 5

        // Create the header rows
        const row1: Row = [`Labour - ${employee.fluidraEmployeeId} ${employee.firstName} ${employee.lastName}`]
        const row2: Row = ["Department", departments[employee.department]];
        const row3: Row = [null, null, null, null, null];
        const row4: Row = ['Week ending', 'Leave', 'Public Holiday', 'Project Hours Available', "Total Hours Logged"];

        // Add projects
        for (var project of filteredProjects) {
            if (project.fluidraProjectId != null) {
                // Update column map
                columnMap[project.id] = currentColumn;
                currentColumn += 1;
                
                // Add column headers
                row3.push(project.fluidraProjectId);
                row4.push(project.name);
            }
        }

        // Add the rows
        ws.addRow(row1);
        ws.addRow(row2);
        ws.addRow(row3);
        ws.addRow(row4);
        
        // Prepare while loop
        var i = 1;
        var mondayDate = getMondayOfWeek(year, 1);
        var sundayDate = getSundayOfWeek(year, 1);
        
        // Loop through each week of the year
        while (mondayDate.getFullYear() === year) {
            // Create the row
            var row = new Array(currentColumn);
            row[columnMap["Week ending"]] = sundayDate.toLocaleDateString();
            
            // Get the logs for that week
            var weeksLogs: Logs = {};
            for (const date in logs) {
                const logDate = SQLFormatToDate(date)
                if (logDate >= mondayDate && logDate <= sundayDate) {
                    weeksLogs[date] = logs[date];
                }
            }
            
            // Add data to the row
            for (var logDay of Object.values(weeksLogs)) {
                for (var log of logDay) {
                    var columnNumber;
                    var hours = log.hours;
                    
                    // Deal with special projects
                    if (log.projectId === -2) {
                        // Public holiday
                        columnNumber = columnMap["Public Holiday"]
                        hours = 8;
                    } else if (log.projectId === -1) {
                        // Leave
                        columnNumber = columnMap["Leave"]
                    } else if (log.projectId === 0) {
                        break;
                    } else {
                        columnNumber = columnMap[log.projectId];
                    }

                    if (row[columnNumber] === undefined) {
                        row[columnNumber] = hours;
                    } else {
                        row[columnNumber] += hours;
                    }
                }
            }
            
            // Add project hours available formula
            var formulaStartCell = rowAndColNumToCell(4+i, 2);
            var formulaEndCell = rowAndColNumToCell(4+i, 3);
            row[3] = { formula: `${GLOBALS.workHoursInWeek} - SUM(${formulaStartCell}:${formulaEndCell})` };
                    
            // Add total hours logged forumula
            var formulaStartCell = rowAndColNumToCell(4+i, 6);
            var formulaEndCell = rowAndColNumToCell(4+i, currentColumn);
            row[4] = { formula: `SUM(${formulaStartCell}:${formulaEndCell})` };
                        
            // Add row to the spreadsheet
            ws.addRow(row);

            // Increment
            i++
            var mondayDate = getMondayOfWeek(year, i);
            var sundayDate = getSundayOfWeek(year, i);
        }

        // Add totals
        ws.addRow([]);
        ws.addRow([]);
        var totalRow = []
        totalRow.push("Total")
        for (var col = 2; col <= currentColumn; col++) {
            var formulaStartCell = rowAndColNumToCell(4, col);
            var formulaEndCell = rowAndColNumToCell(i, col);
            totalRow.push({ formula: `SUM(${formulaStartCell}:${formulaEndCell})` });
        }
        ws.addRow(totalRow);
        
        // Add Styles

        // Title
        ws.getCell('A1').font = { size: 18, bold: true, color: {argb: '002F75B5'} } 
        ws.getCell('A2').font = { bold: true} 

        // Header font and alignment
        ws.getRow(3).alignment = { horizontal: "center" };
        ws.getRow(4).alignment = { wrapText: true, vertical: "middle", horizontal: "center" };
        ws.getRow(4).font = { bold: true };
        
        // Total row font
        ws.getRow(i + 6).font = { bold: true };

        // Set column widths
        ws.getColumn(1).width = 11;
        ws.getColumn(2).width = 8;
        ws.getColumn(3).width = 8;
        ws.getColumn(4).width = 8;
        ws.getColumn(5).width = 8;
        for (let i = 6; i <= currentColumn; i++) {
            ws.getColumn(i).width = 12;
        }

        // Set row height
        ws.getRow(4).height = 68;
        
        // Freeze panes
        ws.views = [
            {state: 'frozen', xSplit: 5, ySplit: 4, topLeftCell: 'F5'}
        ];
    }
    
    // Download the workbook
    const buffer = await wb.xlsx.writeBuffer();
    downloadExcelFile(buffer, `Tax Report - ${year}`);
};

export default GenerateTaxReport;