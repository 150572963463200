import React from 'react';
import styled from 'styled-components';

import Row from '../components/atoms/Row';
import CapitalisationReport from '../components/pages/AdminReportsPageComponents/CapitalisationReport';
import ProjectReport from '../components/pages/AdminReportsPageComponents/ProjectReport';
import TaxReport from '../components/pages/AdminReportsPageComponents/TaxReport';

const ReportsPage = () => {  
    return (
        <Page>
            <Padding>
                <Row style={{width: '100%', justifyContent: 'center'}}>
                    <CapitalisationReport />
                    <ProjectReport />
                    <TaxReport />
                </Row>
            </Padding>
        </Page>
    );
}

//#region Styles
const Page = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 200px);
`;

const Padding = styled.div`
    padding: 10px 10px 0px 10px;
`
//#endregion

export default ReportsPage;