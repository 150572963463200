import React, { useState } from "react";
import styled from "styled-components";
import { Card, Column } from "../../common/CommonStyles";
import { DropdownEntry } from "../../molecules/DropdownEntry";
import { subtractMonth } from "../../../shared/functions/dateAndTime";
import { DropdownItems } from "../../../shared/types/DropdownItems";
import GenerateTaxReport from "../../../reports/taxReport";
import { GLOBALS } from "../../../values/globals";
import { delay } from "../../../shared/functions/functions";
import { DownloadSpreadsheetButton } from "../../common/Buttons";
import { AdminCheckbox } from "../../common/FormInputs";

const TaxReport = () => {
    const currentDate: Date = new Date();
    const currentYear: number = currentDate.getFullYear();

    const [ year, setYear] = useState<number>(currentYear - 1);
    const [ loading, setLoading] = useState<boolean>(false);

    const startYear = 2023;
    const years: DropdownItems = {}
    for (let year = startYear; year <= currentYear; year++) {
        years[year] = year.toString();
    }
    
    const handleDownloadTaxReport = async () => {
        setLoading(true);

        // Add a minimum loading time for user feedback
        await Promise.all([
            await GenerateTaxReport(year),
            delay(GLOBALS.buttonLoadTime)
        ]);

        setLoading(false);
    }

    return (
        <Card>
            <Column style={{height: '100%', justifyContent: 'space-between'}}>
                <Column>
                    <Title>Tax Report</Title>
                    <DropdownEntry
                        required
                        showHeading
                        headingText="Year"
                        placeholder="Select a year"
                        options={years}
                        value={year}
                        setValue={(value: any) => setYear(Number(value))} />
                    {/* Departments */}
                    {/* <Column>
                        {Object.entries(departments).map(([]) => (

                        ))

                        }
                        <AdminCheckbox value={false} setValue={() => {}} />
                    </Column> */}
                </Column>
                <DownloadSpreadsheetButton
                    onClick={handleDownloadTaxReport}
                    buttonText={"Download Tax Report"}
                    loading={loading} />
            </Column>
        </Card>
    )
}

const Title = styled.span`
    font-size: 24pt;
    font-weight: bold;
    text-align: start;
    margin-bottom: 20px;
`;

export default TaxReport;